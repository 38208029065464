<template>
	<div class="section section--sm">
		<div class="container">
			<div class="section__wrap">
				<div class="section__cnt">
					<div class="section__head section__head--table">
						<div class="title title--section">Profile</div>
					</div>

					<div class="section__main">
						<div class="profile">
							<div class="profile__main">
								<div class="user">
									<div class="user__logo">
										<div class="logo">
											<div class="logo__img">
												<img :src="require('@img/ava/ava1.webp')" alt="">
											</div>
											<div class="logo__bg">
												<img :src="require('@img/ranks/rank_gold.webp')" alt="">
											</div>

											<!--<div class="user__edit" @click="onEditAva">-->
												<!--<font-awesome-icon icon="fa-regular fa-pen"/>-->
											<!--</div>-->
										</div>
									</div>

									<div class="user__wrap">
										<div class="user__info">
											<div class="user__login">{{user.name}}</div>
											<div class="user__country">
												<font-awesome-icon icon="fa-regular fa-envelope"/>
												<span>{{user.email}}</span>
											</div>
										</div>
									</div>

									<!--<div class="user__coins">-->
										<!--<div class="user__coinTitle">Balance:</div>-->
										<!--<div class="user__coinsCoin">-->
											<!--<font-awesome-icon icon="fa-regular fa-coins"/>-->
											<!--<span>345$</span>-->
										<!--</div>-->
										<!--<div class="user__coinBtn">-->
											<!--<router-link to="/money" class="btn">More info</router-link>-->
										<!--</div>-->
									<!--</div>-->
								</div>
							</div>

							<!--<div class="profile__acc">-->
								<!--<div class="acc">-->
									<!--<ul class="acc__list">-->
										<!--<li class="acc__item" v-for="i in 3">-->
											<!--<div class="acc__card">-->
												<!--<div class="acc__inf">Open access</div>-->
												<!--<div class="acc__logo">-->
													<!--<img src="http://api.darkboosting.com/storage/games/1.png" alt="">-->
												<!--</div>-->
												<!--<div class="acc__title">Rainbowsix</div>-->
												<!--<div class="acc__status">-->
													<!--<v-icon name="gamepad"></v-icon>-->
													<!--<span>Master</span>-->
												<!--</div>-->
											<!--</div>-->
										<!--</li>-->
									<!--</ul>-->
								<!--</div>-->
							<!--</div>-->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

	export default {
		name: 'profile-index',
		computed:{
			...mapState({
				user: state => state.auth.user
			})
		},
		methods:{
			onEditAva(){

			}
		}
	};
</script>

<style scoped lang=scss>
	.profile{
		display: flex;
		align-items: center;
		&__main{
			width: 40%;
			margin: 0 auto;
		}
		&__acc{
			width: 60%;
		}
	}
	.user{
		padding: 70px 25px 60px;
		width: 370px;
		position: relative;
		margin: 0 auto;
		@media(max-width: $md){
			max-width: 300px;
		}
		&__edit{
			position: absolute;
			top: -26px;
			right: 95px;
			cursor: pointer;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 0;
			border: 1px solid $c_alt;
			transition: all 0.3s;
			&:hover{
				border: 1px solid transparent;
			}
			svg{
				fill: $c_alt;
			}

		}
		&__info{
			text-align: center;
		}
		&__login{
			font-size: 20px;
			font-weight: bold;
			line-height: 26px;
			margin-bottom: 5px;
			padding-top: 3px;
		}
		&__country{
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			justify-content: center;
			&:last-child{
				margin-bottom: 0;
			}
			svg{
				margin-right: 10px;
				fill: #171026;
				width: 20px;
			}
			span{
				font-size: 18px;
				line-height: 24px;
			}
		}
		&__leveling{
			border-top: 1px solid $c_border_light;
			margin-top: 30px;
			padding-top: 25px;
		}
		&__levTop{
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 5px;
			font-family: $f_alt;
		}
		&__line{
			height: 10px;
			background: $c_border_light;
			position: relative;
			span{
				background: $c_alt;
				position: absolute;
				display: block;
				height: 100%;
				left: 0;
				top: 0;
			}
		}
		&__pass{
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
		}
		&__bot{
			.btn{
				width: 100%;
			}
		}
		&__coins{
			padding-top: 40px;
		}
		&__coinTitle{
			text-align: center;
			font-weight: bold;
			text-transform: uppercase;
			color: $c_text_light;
			font-size: 14px;
		}
		&__coinsCoin{
			display: flex;
			justify-content: center;
			align-items: center;
			svg{
				margin-right: 5px;
				fill: $c_alt;
			}
			span{
				font-family: $f_alt;
				color: $c_alt;
				font-size: 24px;
			}
		}
		&__coinBtn{
			display: flex;
			justify-content: center;
		}
	}

	.logo{
		position: relative;
		max-width: 320px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		line-height: 0;
		margin-bottom: 15px;
		min-width: 90px;
		&__img{
			line-height: 0;
			width: 80px;
			height: 80px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__bg{
			width: 320px;
			height: 120px;
			position: absolute;
			top: -13px;
			left: 18px;
			@media(max-width: $md){
				left: -16px;
			}
		}
	}

	.acc{
		&__list{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		&__item{
			width: 33.333%;
			padding: 0 10px;
			margin: 80px 0;
		}
		&__card{
			text-align: center;
		}
		&__inf{
			text-transform: uppercase;
			color: $c_success;
			font-family: $f_alt;
		}
		&__logo{
			img{
				max-width: 60px;
			}
		}
		&__title{
			font-family: $f_alt;
			color: $c_alt;
			font-size: 18px;
			margin-bottom: 10px;
		}
		&__status{
			width: 100px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			background: $c_success;
			padding: 2px 10px;
			border-radius: 5px;
			svg{
				fill: #fff;
				margin-right: 10px;
				width: 30px;
			}
			span{
				font-size: 18px;
				font-family: $f_alt;
			}
		}
	}
</style>
